import { trackCHEvents } from "@analytics/utils/trackEvents";
import { compact } from "@utils/fn-utils";
import { getPageName } from "./getPageName";

export const trackCTAs = ({ element_name, articleId = undefined, data = undefined }) => {
  // let page = window.location.pathname.split("/")[1]
  //   ? window.location.pathname.split("/")[1]
  //   : "feeds";
  let page = getPageName();
  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.value) {
      page = `${page}_listing`;
    }
  }

  const dataObj = articleId ? { article_id: articleId } : data;
  const payload = compact({
    element_name,
    element_type: "button",
    action_type: "click",
    screenName: articleId ? "news_details" : page,
    data: dataObj
  });

  trackCHEvents(`${articleId ? "news_details" : page}_page_${element_name}_button_click`, payload);
};

export const trackVideoArticleVisible = ({ data }) => {
  let pathname = window.location.pathname.split("/");
  let page = getPageName();

  page = pathname[pathname.length - 1];

  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());

    if (queryParams?.value) {
      page = `${page}_listing`;
    }
  }

  const payload = compact({
    eventCategory: "video_article_visible",
    screenName: `${page}`,
    ...data
  });
  trackCHEvents(`${page}_page_video_article_card_visible`, payload);
};

export const trackVideoArticleSeen = ({ pageType, data }) => {
  // page =
  //   window.location.pathname.split("/") && window.location.pathname.split("/")[1] !== ""
  //     ? window.location.pathname.split("/")[1]
  //     : "feeds";
  let page = getPageName();
  const payload = compact({
    eventCategory: "video_article_view",
    screenName: pageType ? pageType : page,
    ...data
  });
  trackCHEvents(`${pageType ? pageType : page}_page_video_article_view`, payload);
};
