import getPageFromUrl from "./getPageFromUrl";

export const setHistory = (pathname) => {
  const { lastLocation, currentLocation, currentLocationPage } =
    window.__bgHistory;

  const isDirectLand = !lastLocation && !currentLocation;
  const isRouteChanged = !!currentLocation && currentLocation !== pathname;

  if (!isDirectLand && !isRouteChanged) return;

  const history = {
    lastLocation: null,
    lastLocationPage: null,
    currentLocation: "",
    currentLocationPage: "",
  };

  if (isRouteChanged) {
    history.lastLocation = currentLocation;
    history.lastLocationPage = currentLocationPage;
  }

  history.currentLocation = pathname;
  history.currentLocationPage = getPageFromUrl(pathname);

  window.__bgHistory = history;
};
