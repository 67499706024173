const isEmptyCollection = (value) => {
  return (
    value === undefined ||
    value === null ||
    (Array.isArray(value) && !value.length) ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const compact = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (isEmptyCollection(obj[key])) {
      delete obj[key];
    }
  });
  return obj;
};
