const urlToScreenMapObj = {
  "/about": "about",
  "/saved_for_later": "saved_for_later",
  "/terms_of_use": "terms_of_use",
  "/privacy_policy": "privacy_policy",
  "/search": "search",
  "/": "feed",
};

export default function getPageFromUrl(url) {
  const pathname = typeof url === "undefined" ? window.location.pathname : url;

  let category = Object.keys(urlToScreenMapObj).find((path) => {
    const regEx = new RegExp(path);
    return regEx.test(pathname);
  });

  category = urlToScreenMapObj[category] || undefined;

  return category;
}
