export const sanitizeCTapPayload = (payload) => {
  const p = {};
  Object.keys(payload).forEach((key) => {
    if (
      !(
        Array.isArray(payload[key]) ||
        (typeof payload[key] === "object" && payload[key] !== null)
      )
    ) {
      p[key] = payload[key];
    }
  });

  return p;
};
