// import newRelicLogger from "./new-relic-logger";

import { getMyTrackerParams } from "@analytics/tracker/clickhouseHandler";
import Publisher from "@analytics/tracker/Publisher";
import { getPageName } from "@utils/getPageName";

let errorLoggerInstance = null;
// let currentBranch;

export const EXCEPTION_EVENTS = {
  WINDOW: "window_error",
  CONSOLE: "console_error",
  API_CATCH: "api_catch",
  ERROR_BOUNDARY: "error_boundary",
  SERVER_ERROR: "server_error"
};

const parseJSON = (...args) => {
  try {
    return JSON.parse(...args);
  } catch (error) {
    // NOTE :: Else it will also logs error
    return false;
  }
};

export const addErrorObjToDimentions = (dimObj, errorObj) => {
  const returnObj = { ...dimObj };
  if (typeof errorObj === "object" && errorObj !== null) {
    returnObj.exceptionName = errorObj.name;
    returnObj.exceptionMessage = errorObj.message;
    returnObj.stack = errorObj.stack;
    returnObj.exception = JSON.stringify(errorObj);
  } else {
    returnObj.exception = errorObj;
  }
  return returnObj;
};

function getPage(_pageType = "currentLocationPage") {
  let page = getPageName();

  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params?.value) {
      page = `${page}_listing`;
    }
  }
  return page;
}

function logErrorInCH(error_, { exceptionName, errorObj, screenName = "", ...dimObj }) {
  const enableErrorLogging = parseJSON(process.env.NEXT_PUBLIC_ENABLE_ERROR_LOGGING);
  // condition to prevent error logging from QA automation in prod environment
  if (enableErrorLogging) {
    const dimObjWithErr = addErrorObjToDimentions(dimObj, errorObj);
    const userId = localStorage.getItem("id");
    window.inTrack.push({
      eventType: "exception",
      data: {
        eventName: exceptionName,
        eventCategory: "exception",
        eventTime: new Date().getTime(),
        dimensions: getMyTrackerParams({
          ...dimObjWithErr,
          currentBranch: process.env.NEXT_PUBLIC_ERROR_LOGGING_BRANCH_NAME // TODO: getCurrentBranch() to fetch the latest branch name
        }),
        property: "Techminis",
        source: "Web",
        userId,
        screenName: screenName || getPage() || ""
      }
    });
  }
}

const addWindowErrorsToLogger = () => {
  // eslint-disable-next-line func-names
  window.onerror = function (message, source, lineno, colno, error) {
    // message, source, lineno, colno, error
    logErrorInCH("error", {
      exceptionName: EXCEPTION_EVENTS.WINDOW,
      errorObj: error,
      message,
      source,
      lineno,
      colno
    });
  };

  const consoleErr = window.console.error;
  window.console.error = (...rest) => {
    if (typeof rest[0] === "string" && !rest[0].includes("Warning:"))
      logErrorInCH("error", {
        exceptionName: EXCEPTION_EVENTS.CONSOLE,
        message: rest.join(", ")
      });
    consoleErr(...rest);
  };
};

export default function logger() {
  if (!errorLoggerInstance) {
    errorLoggerInstance = new Publisher();
    errorLoggerInstance.subscribe(logErrorInCH);
    addWindowErrorsToLogger();
  }
  return errorLoggerInstance;
}
