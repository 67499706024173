/* eslint-disable no-unused-vars */
import { getCookies, getCookie, setCookie, deleteCookie } from "cookies-next";
const isProduction = process.env.NODE_ENV === "production";

const cookieOptions = {
  maxAge: 365 * 24 * 3600,
  sameSite: "none",
  secure: isProduction ? true : false
};

const _getCookies = (params) => {
  // params should be {req, res} required only for SSR cookie
  if (typeof params === "object") {
    return getCookies(params);
  }
  return getCookies();
};

const _getCookie = (name, params) => {
  if (typeof params === "object") {
    return getCookie(name, params);
  } else {
    return getCookie(name);
  }
};

const _setCookie = (name, value, params) => {
  if (typeof params === "object") {
    setCookie(name, value, { ...params, ...cookieOptions });
  } else {
    setCookie(name, value, { ...cookieOptions });
  }
};

const _deleteCookie = (name, params) => {
  if (typeof params === "object") {
    deleteCookie(name, { ...params, ...cookieOptions });
  } else {
    deleteCookie(name, { ...cookieOptions });
  }
};

export {
  _getCookies as getCookies,
  _getCookie as getCookie,
  _setCookie as setCookie,
  _deleteCookie as deleteCookie
};

export const disableScroll = () => {
  document.querySelector("html").classList.add("noScroll");
  document.querySelector("body").classList.add("noScroll");
};

export const enableScroll = () => {
  document.querySelector("html").classList.remove("noScroll");
  document.querySelector("body").classList.remove("noScroll");
};

export const getFeedDetails = ({
  feedTemp,
  isTrending,
  hasDiscoverMoreTags,
  hasTrendingTopics,
  // hasSecondaryDiscoverMoreTags, // REMOVED FOR TIME BEING - WIDGET FOR TRENDING
  isSignUp,
  pathname,
  hasTrendingArticles
}) => {
  const isNaukriPage = pathname.includes("/naukri");
  if (feedTemp.length) {
    if (isTrending) {
      feedTemp.map((el, index) => {
        // added Bigshyft touch point
        if (!isNaukriPage && feedTemp.length >= 1 && index === 1 && el !== "bigshyftBanner") {
          feedTemp.splice(index, 0, "bigshyftBanner");
        } else if (index === 0 && el !== "feature") {
          // feedTemp.unshift("feature");
        }
        // if (index === 2 && el !== "wordCoach") {
        //   feedTemp.splice(index, 1, "wordCoach");
        // }
        // else if (feedTemp.length >= 3 && index === 2 && el !== "story1") {
        //   feedTemp.splice(index, 0, "story1"); //To be only shown if feed length is >= 18
        // }
        // else if (feedTemp.length >= 5 && index === 4 && hasTrendingTopics) {
        //   if (el !== "trendingTopics") {
        //     feedTemp.splice(index, 0, "trendingTopics");
        //   } else {
        //     feedTemp.splice(index, 0);
        //   }
        // }

        // else if (feedTemp.length >= 7 && index === 6 && el !== "story2") {
        //   feedTemp.splice(index, 0, "story2"); //To be only shown if feed length is >= 18
        // }
        // else if (feedTemp.length >= 12 && index === 10 && el !== "download") {
        //   feedTemp.splice(index, 0, "download"); //To be only shown if feed length is >= 18
        // }
        // else if (feedTemp.length >= 15 && index === 14 && el !== "story3") {
        //   feedTemp.splice(index, 0, "story3"); //To be only shown if feed length is >= 18
        // }
        // else if (feedTemp.length >= 19 && index === 18 && el !== "story4") {
        //   feedTemp.splice(index, 0, "story4"); //To be only shown if feed length is >= 18
        // } else if (feedTemp.length >= 23 && index === 22 && el !== "story5") {
        //   feedTemp.splice(index, 0, "story5"); //To be only shown if feed length is >= 18
        // }

        // REMOVED FOR TIME BEING - WIDGET FOR TRENDING
        // else if (feedTemp.length >= 10 && index === 9 && hasSecondaryDiscoverMoreTags) {
        //   if (el !== "secondaryDiscoverMore") {
        //     feedTemp.splice(index, 0, "secondaryDiscoverMore");
        //   } else {
        //     feedTemp.splice(index, 0);
        //   }
        // }
        // else if (feedTemp.length >= 15 && index === 14 && el !== "download") {
        //   feedTemp.splice(index, 0, "download");
        // } else if (
        //   feedTemp.length >= 20 &&
        //   index === 19 &&
        //   el !== "subscribe" &&
        //   !isSignUp &&
        //   !pathname?.includes("/naukri")
        // ) {
        //   feedTemp.splice(index, 0, "subscribe");
        // }
      });
    } else {
      feedTemp.map((el, index) => {
        // added Bigshyft touch point
        if (!isNaukriPage && feedTemp.length >= 1 && index === 1 && el !== "bigshyftBanner") {
          feedTemp.splice(index, 0, "bigshyftBanner");
        } else  if (index === 0 && el !== "feature") {
          // feedTemp.unshift("feature");
        } else if (feedTemp.length >= 4 && index === 3 && hasTrendingArticles) {
          if (el !== "trending" && !isTrending) {
            feedTemp.splice(index, 0, "trending");
          } else {
            feedTemp.splice(index, 0);
          }
        } else if (feedTemp.length >= 7 && index === 6 && hasDiscoverMoreTags) {
          if (el !== "discoverMoreWithTags" && !isTrending) {
            feedTemp.splice(index, 0, "discoverMoreWithTags");
          } else {
            feedTemp.splice(index, 0);
          }
        } else if (feedTemp.length >= 12 && index === 10 && el !== "download") {
          feedTemp.splice(index, 0, "download"); //To be only shown if feed length is >= 18
        }

        // else if (
        //   feedTemp.length >= 10 &&
        //   index === 9 &&
        //   el !== "subscribe" &&
        //   !isSignUp &&
        //   !pathname?.includes("/naukri")
        // ) {
        //   feedTemp.splice(index, 0, "subscribe");
        // } else if (feedTemp.length >= 13 && index === 12 && el !== "download") {
        //   feedTemp.splice(index, 0, "download");
        // }
      });
    }
  }

  return feedTemp;
};
