import { getCookie, setCookie } from "@utils/util";
import {
  doc,
  setDoc,
  getDoc,
  getFirestore,
  getDocs,
  collection,
  updateDoc
} from "firebase/firestore/lite";
import { nanoid } from "nanoid";

let userRef = null;

class firestoreService {
  constructor() {
    // const userID = localStorage.getItem("id");
    // if (!userID) {
    //   localStorage.setItem("id", nanoid());
    // }
  }

  get docRef() {
    if (!userRef) {
      // const userID = localStorage.getItem("id");
      const userID = getCookie("techminis_u_id");

      const db = getFirestore();
      userRef = doc(db, "users", userID);
    }
    return userRef;
  }

  async fetchCollectionData(collectionName) {
    const db = getFirestore();
    const querySnapshot = await getDocs(collection(db, collectionName));
    const data = querySnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    return data;
  }

  async addPuzzlesDataToFirestore(dataArray, category) {
    try {
      const db = getFirestore();
      const puzzlesRef = doc(db, "puzzles", category);

      for (const data of dataArray) {
        const id = data.id;

        // Check if the question already exists in the collection
        const existingDoc = await getDoc(puzzlesRef);

        if (!existingDoc.exists()) {
          const newPuzzles = [data];
          await setDoc(puzzlesRef, { [category]: newPuzzles });
          console.log(`Data added successfully for id: ${id}`);
          console.count("data pushed");
        } else {
          const existingPuzzles = existingDoc.data()[category];
          const isDuplicate = existingPuzzles.some((puzzle) => puzzle.id === id);

          if (!isDuplicate) {
            const updatedPuzzles = [...existingPuzzles, data];
            await updateDoc(puzzlesRef, { [category]: updatedPuzzles });
            console.log(`Data added successfully for id: ${id}`);
            console.count("data pushed");
          } else {
            console.log(`Skipping duplicate id: ${id}`);
          }
        }
      }

      console.log("All data processed.");
    } catch (error) {
      console.error("Error adding data:", error);
    }
  }

  async fetchUnseenPuzzles(userPuzzles, category) {
    const db = getFirestore();
    try {
      // Get the specific category document from the "puzzles" collection
      const categoryDoc = await getDoc(doc(db, "puzzles", category));

      // Return an empty array if the category document does not exist
      if (!categoryDoc.exists()) {
        return [];
      }

      const categoryPuzzles = categoryDoc.data()[category];

      // Create an array to store unseen puzzle objects
      const unseenPuzzles = [];

      categoryPuzzles.forEach((puzzle) => {
        if (!userPuzzles.includes(puzzle.id)) {
          const temp = { id: puzzle.id, ...puzzle };
          unseenPuzzles.push(temp);
        }
      });

      return unseenPuzzles.slice(0, 5);
    } catch (error) {
      console.error("Error fetching puzzles:", error);
      throw error;
    }
  }

  async addCorrectAnswerPuzzle(id) {
    try {
      const userData = await this.getUserData();
      const correctAnswerPuzzles = userData?.correctAnswerPuzzles || [];

      if (!correctAnswerPuzzles.includes(id)) {
        await setDoc(this.docRef, {
          ...userData,
          correctAnswerPuzzles: [...correctAnswerPuzzles, id]
        });
        this.setUserData({
          ...userData,
          correctAnswerPuzzles: [...correctAnswerPuzzles, id]
        });
      }
    } catch (e) {
      console.log("Updation failed: ", e);
      const userData = await this.getUserData();
      const correctAnswerPuzzles = userData?.likes || [];

      if (!correctAnswerPuzzles.includes(id)) {
        this.setUserData({
          ...userData,
          correctAnswerPuzzles: [...correctAnswerPuzzles, id]
        });
      }
    }
  }

  async getUserData() {
    try {
      if (localStorage.getItem("userData")) {
        return JSON.parse(localStorage.getItem("userData"));
      } else {
        const docSnap = await getDoc(this.docRef);
        localStorage.setItem("userData", JSON.stringify(docSnap.data()));
        return docSnap.data();
      }
    } catch (e) {
      console.error("Data Fetch failed: ", e);
    }
  }

  setUserData(data) {
    localStorage.setItem("userData", JSON.stringify(data));
  }

  async addUserPuzzlesIds(id) {
    try {
      const userData = await this.getUserData();
      const userPuzzles = userData?.puzzles || [];

      if (!userPuzzles.includes(id)) {
        await setDoc(this.docRef, {
          ...userData,
          puzzles: [...userPuzzles, id]
        });
        this.setUserData({
          ...userData,
          puzzles: [...userPuzzles, id]
        });
      }
    } catch (e) {
      console.log("Updation failed: ", e);
      const userData = await this.getUserData();
      const userPuzzles = userData?.puzzles || [];

      if (!userPuzzles.includes(id)) {
        this.setUserData({
          ...userData,
          puzzles: [...userPuzzles, id]
        });
      }
    }
  }

  async addUserArticlesLike(articleId) {
    try {
      const userData = await this.getUserData();
      const userArticlesLike = userData?.likes || [];

      if (!userArticlesLike.includes(articleId)) {
        await setDoc(this.docRef, {
          ...userData,
          likes: [...userArticlesLike, articleId]
        });
        this.setUserData({
          ...userData,
          likes: [...userArticlesLike, articleId]
        });
      }
    } catch (e) {
      console.log("Updation failed: ", e);
      const userData = await this.getUserData();
      const userArticlesLike = userData?.likes || [];

      if (!userArticlesLike.includes(articleId)) {
        this.setUserData({
          ...userData,
          likes: [...userArticlesLike, articleId]
        });
      }
    }
  }

  async deleteUserArticlesLike(articleId) {
    try {
      const userData = await this.getUserData();
      const userArticlesLike = userData?.likes || [];

      if (userArticlesLike.includes(articleId)) {
        await setDoc(this.docRef, {
          ...userData,
          likes: userArticlesLike.filter((id) => id !== articleId)
        });
        this.setUserData({
          ...userData,
          likes: userArticlesLike.filter((id) => id !== articleId)
        });
      }
    } catch (e) {
      console.log("Deletion failed: ", e);
      const userData = await this.getUserData();
      const userArticlesLike = userData?.likes || [];

      if (userArticlesLike.includes(articleId)) {
        this.setUserData({
          ...userData,
          likes: userArticlesLike.filter((id) => id !== articleId)
        });
      }
    }
  }

  // async addUserBookmarkGroup(groupName) {
  //   try {
  //     const userData = await this.getUserData();
  //     const userBookmark = userData?.bookmarks || [];
  //     let isNeedUpdate = true || !!userBookmark.length;
  //     for (let i = 0; i < userBookmark.length; i += 1) {
  //       if (userBookmark[i].groupName === groupName) {
  //         isNeedUpdate = false;
  //         break;
  //       }
  //     }
  //     if (isNeedUpdate) {
  //       await setDoc(
  //         this.docRef,
  //         {
  //           ...userData,
  //           bookmarks: [...(userData?.bookmarks || []), { groupName, articleIds: [] }]
  //         },
  //         { merge: true }
  //       );
  //       this.setUserData({
  //         ...userData,
  //         bookmarks: [...(userData?.bookmarks || []), { groupName, articleIds: [] }]
  //       });
  //     }
  //   } catch (e) {
  //     console.log("Updation failed", e);
  //   }
  // }

  // async updateBookmark({ groupName, bookmarkId, remove = false }) {
  //   const getGroupName = (userData, id) => {
  //     const bookmark = userData?.bookmarks || [];
  //     const groupName = bookmark?.map((item) => {
  //       if (item?.articleIds?.includes(id)) {
  //         return item.groupName;
  //       }
  //     });

  //     return groupName.filter((item) => item !== undefined)[0] || "";
  //   };

  //   try {
  //     const userData = await this.getUserData();
  //     const pseudoGroup = getGroupName(userData, bookmarkId) || groupName;
  //     const userBookmarks = userData?.bookmarks || [];
  //     let groupMatched = false;
  //     const updatedList = userBookmarks.map((tagObj) => {
  //       const _tagObj = { ...tagObj };
  //       if (tagObj.groupName === pseudoGroup) {
  //         groupMatched = true;
  //         return {
  //           groupName: pseudoGroup,
  //           articleIds: remove
  //             ? _tagObj.articleIds.filter((id) => id !== bookmarkId)
  //             : !!_tagObj?.articleIds
  //             ? _tagObj?.articleIds?.concat([bookmarkId])
  //             : (_tagObj.articleIds = [bookmarkId])
  //         };
  //       } else {
  //         return _tagObj;
  //       }
  //     });

  //     if (!remove && !groupMatched) {
  //       // case of addition when groupname donot exist
  //       updatedList.push({ groupName: groupName, articleIds: [bookmarkId] });
  //     }
  //     await setDoc(
  //       this.docRef,
  //       {
  //         ...userData,
  //         bookmarks: updatedList
  //       },
  //       { merge: true }
  //     );
  //     this.setUserData({
  //       ...userData,
  //       bookmarks: updatedList
  //     });
  //   } catch (e) {
  //     console.log("Updation failed", e);
  //   }
  // }

  // async addUserBookmarkArticleInGroup(groupName, articleId) {
  //   try {
  //     const userData = await this.getUserData();
  //     let userBookmark = userData?.bookmarks || [];
  //     for (let i = 0; i < userBookmark.length; i++) {
  //       if (
  //         userBookmark[i]?.groupName === groupName &&
  //         !userBookmark[i]?.articleIds.includes(articleId)
  //       ) {
  //         await setDoc(this.docRef, {
  //           ...userData,
  //           bookmarks: [
  //             ...userBookmark.slice(0, i),
  //             {
  //               ...userBookmark[i],
  //               articleIds: [...userBookmark[i].articleIds, articleId]
  //             },
  //             ...userBookmark.slice(i + 1)
  //           ]
  //         });
  //         this.setUserData({
  //           ...userData,
  //           bookmarks: [
  //             ...userBookmark.slice(0, i),
  //             {
  //               ...userBookmark[i],
  //               articleIds: [...userBookmark[i].articleIds, articleId]
  //             },
  //             ...userBookmark.slice(i + 1)
  //           ]
  //         });
  //       }
  //     }
  //   } catch (e) {
  //     console.log("Updation failed", e);
  //   }
  // }

  async addUserNotInteresetedArticles(articleId) {
    try {
      const userData = await this.getUserData();
      const userNotInterestedArticles = userData?.notInterested || [];
      if (!userNotInterestedArticles.includes(articleId)) {
        await setDoc(this.docRef, {
          ...userData,
          notInterested: [...userNotInterestedArticles, articleId]
        });
        this.setUserData({
          ...userData,
          notInterested: [...userNotInterestedArticles, articleId]
        });
      }
    } catch (e) {
      console.error("Updation failed", e);
      const userData = await this.getUserData();
      const userNotInterestedArticles = userData?.notInterested || [];
      if (!userNotInterestedArticles.includes(articleId)) {
        this.setUserData({
          ...userData,
          notInterested: [...userNotInterestedArticles, articleId]
        });
      }
    }
  }

  async addUserReportArticles(articleId) {
    try {
      const userData = await this.getUserData();
      const userReportrticles = userData?.report || [];
      if (!userReportrticles.includes(articleId)) {
        await setDoc(this.docRef, {
          ...userData,
          report: [...userReportrticles, articleId]
        });
        this.setUserData({
          ...userData,
          report: [...userReportrticles, articleId]
        });
      }
    } catch (e) {
      console.error("Updation failed", e);
      const userData = await this.getUserData();
      const userReportrticles = userData?.report || [];
      if (!userReportrticles.includes(articleId)) {
        this.setUserData({
          ...userData,
          report: [...userReportrticles, articleId]
        });
      }
    }
  }

  async addUserPreferences(tagIds, userFirestoreData = null) {
    try {
      const userData = userFirestoreData ? userFirestoreData : await this.getUserData();
      const userPreferences = userData?.preferences || [];

      const setTemp = new Set(userPreferences);

      tagIds?.map((tagId) => {
        setTemp.add(tagId);
      });
      this.setUserData({
        ...userData,
        preferences: [...setTemp]
      });
      await setDoc(this.docRef, {
        ...userData,
        preferences: [...setTemp]
      });
    } catch (e) {
      console.error("Updation failed", e);
      const userData = userFirestoreData ? userFirestoreData : await this.getUserData();
      const userPreferences = userData?.preferences || [];

      const setTemp = new Set(userPreferences);

      tagIds?.map((tagId) => {
        setTemp.add(tagId);
      });
      this.setUserData({
        ...userData,
        preferences: [...setTemp]
      });
    }
  }

  async deleteUserPreferences(tagId) {
    try {
      const userData = await this.getUserData();
      const userPreferences = userData?.preferences || [];
      if (userPreferences.includes(tagId)) {
        await setDoc(this.docRef, {
          ...userData,
          preferences: userPreferences.filter((id) => id !== tagId)
        });
        this.setUserData({
          ...userData,
          preferences: userPreferences.filter((id) => id !== tagId)
        });
      }
    } catch (e) {
      console.error("Deletion failed", e);
      const userData = await this.getUserData();
      const userPreferences = userData?.preferences || [];
      this.setUserData({
        ...userData,
        preferences: userPreferences.filter((id) => id !== tagId)
      });
    }
  }

  // async updateBookmark({ groupName, bookmarkId, remove = false }) {
  //   try {
  //     const userData = await this.getUserData();
  //     const userBookmarks = userData?.bookmarks || [];
  //     let groupMatched = false;
  //     let updatedList = userBookmarks.map((tagObj) => {
  //       const _tagObj = { ...tagObj };
  //       if (tagObj.groupName === groupName) {
  //         groupMatched = true;
  //         return {
  //           groupName,
  //           articleIds: remove
  //             ? _tagObj.articleIds.filter((id) => id !== bookmarkId)
  //             : _tagObj.articleIds.concat([bookmarkId])
  //         };
  //       } else {
  //         return _tagObj;
  //       }
  //     });
  //     if (!remove && !groupMatched) {
  //       // case of addition when groupname donot exist
  //       updatedList.push({ groupName, articleIds: [bookmarkId] });
  //     }
  //     if (remove) {
  //       updatedList = updatedList.filter((el) => el.articleIds.length > 0);
  //     }

  //     await setDoc(
  //       this.docRef,
  //       {
  //         ...userData,
  //         bookmarks: updatedList
  //       },
  //       { merge: true }
  //     );
  //     this.setUserData({
  //       ...userData,
  //       bookmarks: updatedList
  //     });
  //   } catch (e) {
  //     console.log("Updation failed", e);
  //   }
  // }

  async addSavedKeywords(tag) {
    try {
      const userData = await this.getUserData();
      const savedKeywords = userData?.savedKeywords || [];
      if (!savedKeywords.includes(tag)) {
        await setDoc(this.docRef, {
          ...userData,
          savedKeywords: [...savedKeywords, tag]
        });
        this.setUserData({
          ...userData,
          savedKeywords: [...savedKeywords, tag]
        });
      }
    } catch (e) {
      console.error("Updation failed", e);
      const userData = await this.getUserData();
      const savedKeywords = userData?.savedKeywords || [];
      if (!savedKeywords.includes(tag)) {
        this.setUserData({
          ...userData,
          savedKeywords: [...savedKeywords, tag]
        });
      }
    }
  }

  async deleteSavedKeywords(tag) {
    try {
      const userData = await this.getUserData();
      const savedKeywords = userData?.savedKeywords || [];
      const lowerPrefArr = savedKeywords.map((item) => item.toLowerCase());
      const index = lowerPrefArr.indexOf(tag.toLowerCase());

      if (index != -1) {
        savedKeywords.splice(index, 1);
        await setDoc(this.docRef, {
          ...userData,
          savedKeywords: savedKeywords
        });
        this.setUserData({
          ...userData,
          savedKeywords: savedKeywords
        });
      }
    } catch (e) {
      console.error("Update failed: ", e);
      const userData = await this.getUserData();
      const savedKeywords = userData?.savedKeywords || [];
      const lowerPrefArr = savedKeywords.map((item) => item.toLowerCase());
      const index = lowerPrefArr.indexOf(tag.toLowerCase());

      if (index != -1) {
        savedKeywords.splice(index, 1);
        this.setUserData({
          ...userData,
          savedKeywords: savedKeywords
        });
      }
    }
  }

  async followSource(sourceId) {
    try {
      const userData = await this.getUserData();
      const userPreferences = userData.preferences || [];
      if (!userPreferences.includes(sourceId)) {
        await setDoc(
          this.docRef,
          { ...userData, preferences: [...userPreferences, sourceId] },
          { merge: true }
        );
        this.setUserData({
          ...userData,
          preferences: [...userPreferences, sourceId]
        });
      }
    } catch (e) {
      console.error("Updation failed: ", e);
      const userData = await this.getUserData();
      const userPreferences = userData.preferences || [];
      if (!userPreferences.includes(sourceId)) {
        this.setUserData({
          ...userData,
          preferences: [...userPreferences, sourceId]
        });
      }
    }
  }

  async unfollowSource(sourceId) {
    try {
      const userData = await this.getUserData();
      const userPreferences = userData.preferences || [];
      if (userPreferences.includes(sourceId)) {
        await setDoc(
          this.docRef,
          {
            ...userData,
            preferences: userPreferences.filter((item) => item !== sourceId)
          },
          { merge: true }
        );
        this.setUserData({
          ...userData,
          preferences: userPreferences.filter((item) => item !== sourceId)
        });
      }
    } catch (e) {
      console.error("Updation failed: ", e);
      const userData = await this.getUserData();
      const userPreferences = userData.preferences || [];
      if (userPreferences.includes(sourceId)) {
        this.setUserData({
          ...userData,
          preferences: userPreferences.filter((item) => item !== sourceId)
        });
      }
    }
  }

  // LOGIC FOR UPDATING RECENT SEARCHES
  // if length == 5 n item present -> rearrange item
  // if length == 5 n item not present -> simple remove last element and push item at top
  // if length < 5 and item present -> remove item and push item at top
  // if length < 5 and item not present -> push the item

  async addRecentSearch(searchItem) {
    try {
      const userData = await this.getUserData();
      const userRecentSearch = userData.recentSearch || [];
      const isItemPresent = userRecentSearch.includes(searchItem);
      const historyLastIndex = 4;

      if (userRecentSearch.length === 5 && isItemPresent) {
        // re-arrange
        const index = userRecentSearch.indexOf(searchItem);
        userRecentSearch.splice(index, 1);
        await setDoc(
          this.docRef,
          { ...userData, recentSearch: [searchItem, ...userRecentSearch] },
          { merge: true }
        );
        this.setUserData({
          ...userData,
          recentSearch: [searchItem, ...userRecentSearch]
        });
      } else if (userRecentSearch.length === 5 && !isItemPresent) {
        // simple remove last element and push item at top
        userRecentSearch.splice(historyLastIndex, 1);
        await setDoc(
          this.docRef,
          { ...userData, recentSearch: [searchItem, ...userRecentSearch] },
          { merge: true }
        );
        this.setUserData({
          ...userData,
          recentSearch: [searchItem, ...userRecentSearch]
        });
      } else if (userRecentSearch.length < 5 && isItemPresent) {
        // remove item and push item at top
        const index = userRecentSearch.indexOf(searchItem);
        userRecentSearch.splice(index, 1);
        await setDoc(
          this.docRef,
          { ...userData, recentSearch: [searchItem, ...userRecentSearch] },
          { merge: true }
        );
        this.setUserData({
          ...userData,
          recentSearch: [searchItem, ...userRecentSearch]
        });
      } else if (userRecentSearch.length < 5 && !isItemPresent) {
        // push the item at top
        await setDoc(
          this.docRef,
          { ...userData, recentSearch: [searchItem, ...userRecentSearch] },
          { merge: true }
        );
        this.setUserData({
          ...userData,
          recentSearch: [searchItem, ...userRecentSearch]
        });
      }
    } catch (e) {
      console.error("Updation failed: ", e);
      const userData = await this.getUserData();
      const userRecentSearch = userData.recentSearch || [];
      const isItemPresent = userRecentSearch.includes(searchItem);
      const historyLastIndex = 4;

      if (userRecentSearch.length === 5 && isItemPresent) {
        // re-arrange
        const index = userRecentSearch.indexOf(searchItem);
        userRecentSearch.splice(index, 1);

        this.setUserData({
          ...userData,
          recentSearch: [searchItem, ...userRecentSearch]
        });
      } else if (userRecentSearch.length === 5 && !isItemPresent) {
        // simple remove last element and push item at top
        userRecentSearch.splice(historyLastIndex, 1);

        this.setUserData({
          ...userData,
          recentSearch: [searchItem, ...userRecentSearch]
        });
      } else if (userRecentSearch.length < 5 && isItemPresent) {
        // remove item and push item at top
        const index = userRecentSearch.indexOf(searchItem);
        userRecentSearch.splice(index, 1);

        this.setUserData({
          ...userData,
          recentSearch: [searchItem, ...userRecentSearch]
        });
      } else if (userRecentSearch.length < 5 && !isItemPresent) {
        // push the item at top

        this.setUserData({
          ...userData,
          recentSearch: [searchItem, ...userRecentSearch]
        });
      }
    }
  }

  async lastUpdatedDate(detail) {
    try {
      const userData = await this.getUserData();

      await setDoc(this.docRef, {
        ...userData,
        lastUpdatedDate: detail
      });
      this.setUserData({
        ...userData,
        lastUpdatedDate: detail
      });
    } catch (e) {
      console.error("Updation failed", e);
      const userData = await this.getUserData();

      this.setUserData({
        ...userData,
        lastUpdatedDate: detail
      });
    }
  }
  async fetchUserData(userId, isServerSide = true, ctx = {}) {
    let userData;
    try {
      const db = await getFirestore();
      let docRef;
      if (userId) {
        docRef = doc(db, "users", userId);
        const docSnap = userId ? await getDoc(docRef) : null;

        if (docSnap?.exists() && docSnap.data()) {
          userData = docSnap.data();
          if (!isServerSide) {
            localStorage.setItem("id", userId);
            localStorage.setItem("userData", JSON.stringify(userData));
          }

          return userData;
        } else {
          userId = nanoid();
          setCookie("techminis_u_id", userId, ctx);
          if (!isServerSide) {
            localStorage.setItem("id", userId);
          }
          userData = {
            id: userId,
            platform: "web",
            lastUpdatedDate: new Date(),
            preferences: [],
            savedKeywords: [],
            likes: [],
            report: [],
            notInterested: [],
            puzzles: [],
            correctAnswerPuzzles: []
          };
          if (!isServerSide) {
            localStorage.setItem("id", userId);
            localStorage.setItem("userData", JSON.stringify(userData));
          }
          docRef = doc(db, "users", userId);
          await setDoc(docRef, userData, { merge: true });
          return userData;
        }
      } else {
        userId = nanoid();
        setCookie("techminis_u_id", userId, ctx);
        if (!isServerSide) {
          localStorage.setItem("id", userId);
        }
        userData = {
          id: userId,
          platform: "web",
          lastUpdatedDate: new Date(),
          preferences: [],
          savedKeywords: [],
          likes: [],
          report: [],
          notInterested: [],
          puzzles: [],
          correctAnswerPuzzles: []
        };
        if (!isServerSide) {
          localStorage.setItem("id", userId);
          localStorage.setItem("userData", JSON.stringify(userData));
        }
        docRef = doc(db, "users", userId);
        await setDoc(docRef, userData, { merge: true });
        return userData;
      }
    } catch (error) {
      if (!userId) {
        userId = nanoid();
        setCookie("techminis_u_id", userId, ctx);
      }
      const userData = {
        id: userId,
        platform: "web",
        lastUpdatedDate: new Date(),
        preferences: [],
        savedKeywords: [],
        likes: [],
        report: [],
        notInterested: [],
        puzzles: [],
        correctAnswerPuzzles: []
      };
      if (!isServerSide) {
        localStorage.setItem("id", userId);
        localStorage.setItem("userData", JSON.stringify(userData));
      }
      return userData;
    }
  }
}

export default firestoreService;
