export function track(...args) {
  if(typeof window.gtag === "function") {
    const idleTime = window.requestIdleCallback || window.setTimeout;
    idleTime(() => window.gtag(...args));
  }
}

function sendEvent(event) {
  if (!window.dataLayer || !event) return;

  const [eventCategory, eventAction, eventLabel] = event.split("$");
  track({
    event: eventCategory,
    eventAction,
    eventCategory,
    eventLabel,
  });
}

export default function gaExecutor(hitType, { hitName = "", ...payload }) {
  switch (hitType) {
    case "ga_event":
      return sendEvent(hitName);
    default:
      return null;
  }
}
