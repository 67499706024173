/* eslint-disable no-unused-vars */
import "../styles/globals.css";
import "core-js/stable";
import { useEffect, useState } from "react";
import { AppProps } from "next/app";
import { ThemeProvider } from "next-themes";
import { initializeApp } from "firebase/app";
import { firestoreConfig } from "configs/firestoreClient";
import { useTheme } from "next-themes";
import dynamic from "next/dynamic";
import * as clickhouse from "@utils/ch-events";
import { GlobalContextProvider } from "@context/GlobalContext";
import { useRouter } from "next/router";
import Head from "next/head";
import errorLogger from "@analytics/ErrorLogger";
import tracker from "@analytics/tracker";
import { tracking } from "@analytics/scripts/tracking";
import { setHistory } from "@analytics/utils/setHistory";
import ErrorBoundary from "@components/ErrorBoundary";
import { getCookie, setCookie } from "@utils/util";
import supportedBrowsers from "./../supportedBrowsers";
import { globalAppDownloadDuration } from "@components/pages/feedPage/constants";
import { getPageName } from "@utils/getPageName";
import firestoreService from "@firestoreServices/firestoreServices";

const FeedLoading = dynamic(() => import("@components/loadingPages/FeedLoading"));
const GlobalDownloadAppPopup = dynamic(
  () => import("@components/templates/GlobalDownloadAppPopup")
);
const SearchLoading = dynamic(() => import("@components/loadingPages/SearchLoading"));
const SavedForLaterLoading = dynamic(() => import("@components/loadingPages/SavedForLaterLoading"));
const SearchDetailLoading = dynamic(() => import("@components/loadingPages/SearchDetailLoading"));
const SignupDrawer = dynamic(() => import("@components/templates/SignupDrawer"));

initializeApp(firestoreConfig);

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    __bgtracker: any;
    __bgHistory: any;
    dataLayer: any;
    gtag: any;
    __popup: any;
    globalAppPopupTimer: any;
    inTrack: any;
    __bglogger: any;
    globalfeedbackPopup: any;
  }
}

const getUserData = async () => {
  const firestore = new firestoreService();
  let userId = getCookie("techminis_u_id");
  await firestore.fetchUserData(userId, false);
  if (userId) {
    localStorage.setItem("id", userId.toString());
  }
};

function getPageLoader(pathname: string) {
  if (
    pathname?.includes("explore") ||
    pathname?.includes("other_trending_reads") ||
    pathname?.includes("saved_for_later")
  ) {
    return <SearchDetailLoading />;
  } else if (pathname?.includes("search")) {
    return <SearchLoading isLoading={true} />;
  } else {
    return <FeedLoading header="FeedHeader" pathname={pathname} showWebStories={true} />;
  }
  // switch (pathname) {
  //   case "/":
  //   case "/naukri":
  //   case "/my-feeds":
  //     return <FeedLoading header="FeedHeader" pathname={pathname} showWebStories={true} />;
  //   case "/search":
  //     return <SearchLoading isLoading={true} />;
  //   // case "/explore":
  //   //   return <SearchDetailLoading />;
  //   // case "/other_trending_reads":
  //   //   return <SearchDetailLoading />;
  //   // case "/saved_for_later":
  //   //   return <SavedForLaterLoading />;
  //   default:
  //     return <FeedLoading header="SSRHeader" pathname={pathname} />;
  // }
}

function MyApp({ Component, pageProps }: AppProps) {
  // const db = getFirestore();
  const router = useRouter();
  const { pathname, query } = useRouter();
  const { id, articleIds } = query;
  const { theme } = useTheme();
  const [_userDevice, setUserDevice] = useState<any>(null);
  const [isSupported, setIsSupported] = useState(true);
  const [pageLoadingUrl, setLoadingPageUrl] = useState(null);
  const [showDownloadAppPopup, setShowDownloadAppPopup] = useState(false);
  // const [showGoogleSigninPopup, setShowGoogleSigninPopup] = useState(false);

  const excludeGlobalDownloadAppPaths = ["/", "/my-feeds", "/naukri"];

  const handleStart = (url) => {
    const fullUrl = window.location.origin + url;
    const path = new URL(fullUrl)?.pathname || "";
    setLoadingPageUrl(path);
  };
  const handleStop = () => {
    setLoadingPageUrl(null);
  };

  useEffect(() => {
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);
    const pageName = getPageName();

    //@ts-ignore GLOBAL APP DOWNLOAD POPUP

    // NOTE: globalAppPopup key is used to verify if the global app download popup is shown on any route or not
    if (
      !window["globalAppPopup"] &&
      !excludeGlobalDownloadAppPaths.includes(pathname) &&
      !window.__popup &&
      pageName !== "trending" &&
      pageName !== "articles" &&
      pageName !== "my-feeds"
    ) {
      window.globalAppPopupTimer = setTimeout(() => {
        // Load google client API for one tap login

        setShowDownloadAppPopup(true);
        //@ts-ignore
        window.__popup = true;
      }, globalAppDownloadDuration);
    }

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
      //@ts-ignore
      clearTimeout(window.globalAppPopupTimer);
    };
  }, [router]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtagInitializer() {
      window.dataLayer.push(arguments);
    };
    window.gtag("js", new Date());
    window.gtag("config", process.env.NEXT_PUBLIC_GA_TRACKING_ID);
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("newswrapOnLoad");
    // if (user) {
    getUserData();
    // } else {
    //   user = nanoid();
    //   setCookie("id", user);
    //   localStorage.setItem("id", user);
    //   let userData = {
    //     id: user,
    //     platform: "web",
    //     lastUpdatedDate: new Date(),
    //     preferences: [],
    //     savedKeywords: []
    //   };
    //   const userDetail = doc(db, "users", user);
    //   setDoc(userDetail, userData, { merge: true });
    //   localStorage.setItem("userData", JSON.stringify(userData));
    // }
  }, []);

  useEffect(() => {
    let navigatorDetail: any = navigator;
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    // if (pathname.includes("/naukri") || params?.mode === "share") {
    sessionStorage.setItem("params", JSON.stringify(params));
    // }

    setUserDevice(navigatorDetail);

    // const isMobile =
    //   typeof window !== "undefined"
    //     ? navigatorDetail?.userAgent.includes("Mobile") ||
    //       navigatorDetail?.userAgentData?.mobile ||
    //       size.width <= 480
    //     : false;

    // if (!isMobile) {
    //   router.push(process.env.NEXT_PUBLIC_URL);
    // }
    document.body.style.overflow = "hidden";
    window.__bglogger = errorLogger();
    window.__bgtracker = tracker();
    window.__bgHistory = {
      lastLocation: null,
      lastLocationPage: null,
      currentLocation: null,
      currentLocationPage: null
    };
    tracking();

    // Browser support
    if (!supportedBrowsers.test(navigator.userAgent)) {
      setIsSupported(false);
    }
  }, []);

  useEffect(() => {
    setHistory(router.pathname);
  }, [router.pathname]);

  return (
    <>
      <Head>
        <title>Techminis | Providing short summaries for crisp and quick read</title>
        <meta name="ui-version" content={process.env.GIT_KEY} />
        <meta name="description" content="Providing short summaries for crisp and quick read" />
        <meta name="keywords" content="Tech, Techminis, Tech news, News"></meta>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,maximum-scale=1,user-scalable=0"
        />
        <meta name="application-name" content="Techminis" />
        <meta name="apple-mobile-web-app-title" content="Techminis" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#F4F5F7" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#F4F5F7" />

        <meta property="og:title" content="Techminis | Daily dose of tech" key="og:title" />
        <meta
          property="og:description"
          content="Providing short summaries for crisp and quick read"
          key="og:description"
        />
        <meta property="og:site_name" content="Techminis App" />
        {!id && pathname !== "/articles" && (
          <meta property="og:url" content="https://techminis.com" />
        )}
        {!id && pathname !== "/articles" && pathname !== "/naukri" && pathname !== "/" && (
          <meta property="og:image" content="/brandingImage.png" />
        )}
        <meta property="og:type" content="website" />
        {theme === "light" && (
          <meta name="theme-color" content="#F4F5F7" media="(prefers-color-scheme: light)"></meta>
        )}
        {theme === "dark" && (
          <meta name="theme-color" content="#171D27" media="(prefers-color-scheme: light)"></meta>
        )}

        <link rel="preconnect" href="https://videos.nishulk.in" />
        <link rel="preconnect" href="http://cdn.inspectlet.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />

        <link rel="shortcut icon" href="/favicon.ico" />

        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="512x512" href="/android-icon-512x512.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/icons/android-icon-192x192.png" color="#F4F5F7" />
        <link rel="canonical" href="https://techminis.com/" key="canonical" />
        <meta name="robots" content="noindex" key="robots" />
      </Head>

      <ErrorBoundary>
        <GlobalContextProvider>
          <ThemeProvider
            attribute="class"
            enableSystem={false}
            defaultTheme={query.theme ? query.theme.toString() : "light"}
          >
            <div
              className="no-scrollbar mx-auto h-full w-full bg-N100 dark:bg-DN050 xs:w-[480px]"
              id="container"
              style={{
                // height: "calc(100vh - 120px)",
                height: "100%",
                overflowY: "auto",
                scrollBehavior: "smooth",
                // width: "100vw",
                paddingBottom: "30px"
                // webkitOverflowScrolling: "touch",
              }}
            >
              {!isSupported && (
                <div style={{ background: "#ED2939", padding: "8px 20px" }}>
                  <p style={{ color: "white", fontSize: "16px" }}>
                    Your browser version is not compatible to load this content. Please update.
                  </p>
                </div>
              )}
              {/* <GlobalTemplates /> */}
              {!isSupported ? (
                <div
                  id="unsupported-browser-container"
                  style={{
                    height: "calc(100vh - 92px)",
                    overflow: "scroll",
                    scrollBehavior: "smooth"
                  }}
                >
                  {pageLoadingUrl ? (
                    getPageLoader(pageLoadingUrl)
                  ) : (
                    <Component key={router.asPath} {...pageProps} />
                  )}
                </div>
              ) : (
                <>
                  {pageLoadingUrl ? (
                    getPageLoader(pageLoadingUrl)
                  ) : (
                    <Component key={router.asPath} {...pageProps} />
                  )}
                </>
              )}
              {/* GLOBAL DOWNLOAD APP POPUP */}
              {/* {showDownloadAppPopup && (
                <GlobalDownloadAppPopup
                  closePopup={() => {
                    setShowDownloadAppPopup(false);
                    //@ts-ignore
                    window.__popup = false;
                    const container =
                      document.getElementById("unsupported-browser-container") ||
                      document.getElementById("container");
                    container.classList.remove("disable-scroll");
                  }}
                />
              )} */}

              {/* <div className="fixed bottom-3 z-[10] flex w-full items-center justify-center">
                <button
                  className="flex items-center justify-center rounded-full bg-P500 p-3 shadow-customBottom shadow-[#0204092e]"
                  onClick={() => {
                    clickhouse.trackCTAs({
                      element_name: "downloadapp_floating"
                    });
                    window.open("https://link-to.app/T4UOMHoWjf");
                  }}
                >
                  <p className="text-readingTextMedium font-bold leading-readingTextMedium  text-N100">
                    Download app
                  </p>
                </button>
              </div> */}
              {/* {showGoogleSigninPopup && (
                <GoogleOneTapLogin
                  onError={() => setIsError(true)}
                  onSuccess={(response) => handleOnGoogleLoginSuccess(response.email)}
                  googleAccountConfigs={{
                    client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
                    prompt_parent_id: "g_id_onload"
                  }}
                />
              )}

              {isError && <Toast show={true} content="Sorry, something went wrong!" />} */}
              {!query.app && (
                <div
                  onClick={() => {
                    clickhouse.trackCTAs({
                      articleId: id,
                      element_name: "downloadapp_strip"
                    });

                    window.open("https://link-to.app/T4UOMHoWjf");
                  }}
                  className=" absolute bottom-0 z-[600] mx-auto flex w-full max-w-[480px] items-center justify-center rounded-t-md bg-[#F94929F0] px-3 py-1  shadow-customBottom shadow-[#0204092e]"
                >
                  <p style={{ color: "white", fontSize: "15px" }}>
                    For uninterrupted reading,&nbsp;
                    <span
                      className="font-bold"
                      // onClick={() => {
                      //   clickhouse.trackCTAs({
                      //     articleId: id,
                      //     element_name: "downloadapp_strip"
                      //   });

                      //   window.open("https://link-to.app/T4UOMHoWjf");
                      // }}
                    >
                      download the app
                    </span>
                  </p>
                </div>
              )}
            </div>
          </ThemeProvider>
        </GlobalContextProvider>
      </ErrorBoundary>
    </>
  );
}
export default MyApp;
