import Publisher from "./Publisher";
import gaTracker from "./gaHandler";
import clickhouseTracker from "./clickhouseHandler";

let trackerInstance = null;

const parseJSON = (...args) => {
  try {
    return JSON.parse(...args);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export default function tracker() {
  if (!trackerInstance) {
    const enableGA = parseJSON(process.env.NEXT_PUBLIC_TECHMINIS_ENABLE_GA);
    const enableClickHouse = parseJSON(process.env.NEXT_PUBLIC_TECHMINIS_ENABLE_CLICK_HOUSE);

    trackerInstance = new Publisher();

    if (enableGA) trackerInstance.subscribe(gaTracker);
    if (enableClickHouse) trackerInstance.subscribe(clickhouseTracker);
  }
  return trackerInstance;
}
