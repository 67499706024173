export const getPageName = () => {
  let pathname = window.location.pathname.split("/");
  let page = pathname[pathname.length - 1];
  let pageName = page !== "" ? window.location.pathname.split("/")[1] : "trending";

  if (
    pageName === "search" ||
    pageName === "other_trending_reads" ||
    pageName === "saved_for_later" ||
    pageName === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params?.value) {
      pageName = `${pageName}_listing`;
    }
  }
  return pageName;
};
