const intervals = {
  Polling: 60000,
  FeatureSeen: 5000
};

const pageSize = 15;
const feedPageSize = 15;
const feedPageLimit = 50;
const globalAppDownloadDuration = 600000;
const globalfeedbackPopupDuration = 300000; // 5 minutes
const appWidth = 480;
const downloadAppPopupDuration = 3000;
const hideNaukriEducationDuration = 5000;
const drawerWidth = "30rem";
const articleFallbackImageBaseUrl = "https://techminis.com/api/suggestions/channel/placeholder/";
const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export {
  intervals,
  pageSize,
  feedPageSize,
  appWidth,
  drawerWidth,
  articleFallbackImageBaseUrl,
  emailFormat,
  feedPageLimit,
  downloadAppPopupDuration,
  globalAppDownloadDuration,
  globalfeedbackPopupDuration,
  hideNaukriEducationDuration
};
