import { EXCEPTION_EVENTS } from "@analytics/ErrorLogger";
import axios from "axios";
import { captureException } from "@sentry/nextjs";
import { getCookie } from "@utils/util";

const instance = axios.create();

instance.interceptors.request.use(function (config) {
  let platform = "Android";
  if (typeof window !== "undefined") {
    let navigatorDetail = navigator;

    platform = navigatorDetail?.userAgentData
      ? navigatorDetail?.userAgentData.platform
        ? navigatorDetail?.userAgentData.platform
        : "IOS"
      : navigatorDetail.userAgent?.includes("Android")
      ? "Android"
      : "IOS";

    if (sessionStorage.getItem("params") && JSON.parse(sessionStorage.getItem("params")).uid) {
      config.headers.externalUid = JSON.parse(sessionStorage.getItem("params")).uid;
    }
  }
  const userId = getCookie("techminis_u_id")
    ? getCookie("techminis_u_id")
    : typeof window !== "undefined"
    ? localStorage.getItem("id")
    : undefined;
  // const uid = typeof window !== "undefined" ? localStorage.getItem("id") : undefined;
  const uid = typeof window !== "undefined" ? userId : undefined;

  if (!config.headers.uid) {
    config.headers.uid = uid;
  }
  config.headers.platform = platform; //TODO TO BE VERIFIED WITH AMAN

  return config;
});

instance.interceptors.response.use(
  ({ data = {} }) => {
    const { status, data: response } = data;
    if (status >= 200 && status < 300) {
      return response;
    }
  },
  (error) => {
    if (typeof window !== "undefined") {
      window.__bglogger.on("error", {
        exceptionName: EXCEPTION_EVENTS.API_CATCH,
        "api endpoint": error.config.url,
        errorObj: error
      });

      captureException(error);

      return Promise.reject(error);
    }
  }
);

export default instance;
