import { jsBase } from "@apiServices/urlConfig";
import http from "@apiServices/http";

export function tracking() {
  let trackingObj = {};
  let sessionId = localStorage.getItem("sessionId") || 0;
  let sessionExpiry = localStorage.getItem("sessionExpiry") || 0;

  function updateSession() {
    const currentDate = new Date();
    sessionExpiry = currentDate.setMinutes(currentDate.getMinutes() + 30);
    localStorage.setItem("sessionExpiry", sessionExpiry);
  }

  function setSession() {
    const currentDate = new Date();
    const sessionStart = new Date(new Date().toString());
    sessionExpiry = currentDate.setMinutes(currentDate.getMinutes() + 30);
    sessionId = sessionStart.getTime();
    localStorage.setItem("sessionId", sessionId);
    localStorage.setItem("sessionExpiry", sessionExpiry);
    return sessionStart;
  }

  function isSessionValid(sessionTime) {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    return sessionTime >= currentTime;
  }

  const InTracking = function intrack() {
    this.init = (obj) => {
      trackingObj = { ...trackingObj, ...obj.data };
    };
    this.push = (obj) => {
      // to get valid session after 30 min or day change
      let mysession = isSessionValid(sessionExpiry) && sessionId !== 0 ? sessionId : setSession();
      if (typeof mysession === "string") {
        mysession = parseInt(mysession);
      }
      mysession = new Date(mysession).getTime();

      let postURL = null;

      const urlSearchParams = new URLSearchParams(window?.location?.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      const pushObject = {
        ...obj.data,
        sessionId: params.sessionId ? Number(params.sessionId) : mysession,
        url: window.location.pathname
      };

      postURL = `${jsBase}/jsapi/instrument/event`;

      updateSession();

      http
        .post(postURL, pushObject)
        .then(() => {})
        .catch((error) => {
          console.error(error);
        });
    };
  };
  window.inTrack = new InTracking();
}
