/* eslint-disable no-unused-vars */
import { getCookie } from "@utils/util";
import { sanitizeCTapPayload } from "../utils/trackerUtils";
// import { PATH_TO_PAGE_MAPPING } from "./tracker.config";
import { getPageName } from "@utils/getPageName";

const PROPERTY = "Techminis";

export function getMyTrackerParams(payload) {
  return Object.keys(payload).map((k) => ({
    key: k,
    name: payload[k]
  }));
}

function getEventCategory(eventName) {
  if (!eventName) return null;

  if (eventName?.includes("page_load")) {
    return "page_load";
  }

  return "click";
}

function getPage(_pageType = "currentLocationPage") {
  // const pageName = window.__bgHistory[pageType];

  // let page = window.location.pathname.split("/")[1]
  //   ? window.location.pathname.split("/")[1]
  //   : "feeds";
  let pathname = window.location.pathname.split("/");
  // let page = "feeds";
  let page = getPageName();
  // if (pathname.length >= 3) {
  //   page = pathname[2];
  // } else {
  //   page = pathname[1];
  // }
  // page = pathname[pathname.length - 1];
  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params?.value) {
      page = `${page}_listing`;
    }
  }
  return page;
}

function track(...args) {
  const idleCallback = window.requestIdleCallback || window.setTimeout;
  idleCallback(() => window.inTrack.push(...args));
}

function sendEvent(eventName, { eventCategory = null, screenName = "", ...restPayload } = {}) {
  if (!eventName) return;

  const urlSearchParams = new URLSearchParams(window?.location?.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  // const userId = params.userId ? params.userId : localStorage.getItem("id");
  const userId = getCookie("techminis_u_id");

  // const uid = typeof window !== "undefined" ? localStorage.getItem("id") : undefined;

  // console.log({
  //   localStorage: localStorage.getItem("id"),
  //   cookie: getCookie("techminis_u_id"),
  //   cookieDoc: document.cookie
  // });

  const SOURCE = params.source ? params.source : "Web";

  const data = {
    eventName,
    screenName: screenName || getPage() || "",
    eventCategory: eventCategory || getEventCategory(eventName) || "",
    eventTime: new Date().getTime(),
    dimensions: getMyTrackerParams(restPayload),
    property: PROPERTY,
    source: SOURCE,
    userId
  };

  track({
    eventType: "click",
    data
  });
}

export default function clickHouseExecutor(hitType, { hitName = "", payload = {} }) {
  if (!window.inTrack) return null;

  const enrichedPayload = sanitizeCTapPayload(payload);

  switch (hitType) {
    case "ch_event":
      return sendEvent(hitName, enrichedPayload);
    default:
      return null;
  }
}
