import React from "react";
import dynamic from "next/dynamic";
import { EXCEPTION_EVENTS } from "@analytics/ErrorLogger";
const ErrorPage = dynamic(() => import("@components/templates/emptyStates/pageView"));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, info) {
    // You can use your own error logging service here
    window.__bglogger.on("error", {
      exceptionName: EXCEPTION_EVENTS.ERROR_BOUNDARY,
      fn: "ErrorBoundary",
      errorObj: error,
      stack: info.componentStack
    });
  }
  render() {
    // Check if the error is thrown

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="h-full w-screen px-5">
          <ErrorPage
            type="error500"
            onClick={() => window.location.reload()}
            noNavigationBar={true}
            className="h-full w-full py-5"
          />
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
