import { track } from "@analytics/tracker/gaHandler";
// import { getCookies } from "cookies-next";

const excludeSlugs = ["my-feeds", "search", "naukri", "articles"];

export const trackCHEvents = (hitName, payload = {}) => {
  // if (sessionStorage.getItem("params")) {
  const params = JSON.parse(sessionStorage.getItem("params"));
  const slug = window.location.pathname.split("/")[1];
  // const uid = JSON.parse(sessionStorage.getItem("params")).uid;
  const page = window.location.pathname;
  // const cookies = getCookies();
  // const isSignUp = !!cookies["isSignUp"];
  if (
    (payload.screenName === "news_details" || payload.screenName === slug) &&
    !excludeSlugs.includes(slug)
  ) {
    payload = {
      ...payload,
      ...params,
      is_trending: page === "/" || page === "/naukri" ? true : false,
      slug: payload.screenName === "news_details" || payload.screenName === slug ? slug : undefined
      // is_logged_in: isSignUp
    };
  } else {
    payload = {
      ...payload,
      ...params,
      is_trending: page === "/" || page === "/naukri" ? true : false
      // is_logged_in: isSignUp
    };
  }
  // if (uid)
  //   payload = {
  //     ...payload,
  //     uid,
  //     is_trending:
  //       (page === "/" || page === "/naukri") &&
  //       localStorage.getItem("feedPage") === "isTrending"
  //         ? true
  //         : false,
  //   };
  // else {
  //   payload = {
  //     ...payload,
  //     is_trending:
  //       (page === "/" || page === "/naukri") &&
  //       localStorage.getItem("feedPage") === "isTrending"
  //         ? true
  //         : false,
  //   };
  // }
  // }

  window.__bgtracker.on("ch_event", {
    hitName,
    payload
  });
};

export const trackGAEvents = (hitName, payload) => {
  window.__bgtracker.on(`ga_event`, {
    hitName,
    ...(payload ? [{ payload }] : [])
  });
};

export const trackGAPageViewEvent = (page_title) => {
  track("config", process.env.NEXT_PUBLIC_GA_TRACKING_ID, { page_title });
};
